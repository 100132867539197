<template>
  <CommonPage id="home" title="TimeCheckNow" classs="home" :isFullScreen="isFullScreen">
    <section v-if="!isFullScreen" class="home-content pc_padding">
      <div class="time-current-row">
        <div class="time-current-head">
          Time in <span>{{ currentLocation }}</span> Now:
        </div>
        <div v-if="localTime" class="time-current-content">
          <div class="time-current-timer" @click="showBig">
            {{ localTime.format('HH : mm : ss') }}
          </div>
          <div class="time-current-content-week">
            {{ localTime.format('dddd') }} {{ localTime.format('MM/DD/YYYY') }}
          </div>
        </div>
      </div>

      <div class="multi-city-row">
        <div v-for="(city, index) in cities" :key="index" class="multi-city-item cursor"
          @click="selectCity(city)">
          <div class="multi-city-item-city">
            {{ city.name }}
          </div>
          <div class="multi-city-item-time">
            {{ city.time }}
          </div>
          <div class="multi-city-item-date">
            {{ city.day }} {{ city.date }}
          </div>
        </div>
      </div>

      <div class="time-page-descript">
        <div class="home-intro-title m-b-30">
          What's TimeCheckNow?
        </div>
        <div class="home-intro-content m-b-70">
          When you encounter something you can't decide on, the Answer Book Online can provide you
          with a solution. Simply click on the Answer Book to quickly get an answer. <br>
          <br>
          Each time you click on the Answer Book, it will give you an answer. Although the answer
          may not fully match your question or expectations, it can serve as a way of
          self-reflection, helping you think and solve problems from different perspectives. <br>
          <br>
          We hope that the Answer Book can bring you inspiration and enjoyment in life, whether you
          are contemplating a question, making a decision, or simply using it for entertainment.
        </div>
        <div class="home-intro-title m-b-30">
          How to use TimeCheckNow?
        </div>
        <div class="home-intro-content m-b-70">
          When you encounter something you can't decide on, the Answer Book Online can provide you
          with a solution. Simply click on the Answer Book to quickly get an answer. <br>
          <br>
          Each time you click on the Answer Book, it will give you an answer. Although the answer
          may not fully match your question or expectations,
        </div>
      </div>

      <div class="time-page-card-container">
        <div class="home-intro-title m-b-30">
          Something may help
        </div>
        <div class="time-page-card-row">
          <div v-for="(data,i) in card" :key="i" class="time-page-card-item">
            <div class="time-page-card-image">
              <img :src="data.img" :alt="data.title">
            </div>
            <div class="time-page-card-content">
              <div class="time-page-card-title">
                {{ data.title }}
              </div>
              <div class="time-page-card-desc">
                {{ data.desc }}
              </div>
            </div>
            <div class="time-page-card-btn" @click="goto(data.routename)">
              Read more
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-else class="full-screen-content" @click="exitFullScreen">
      <div v-if="localTime" class="full-screen-timer">
        {{ localTime.format('HH : mm : ss') }}
      </div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';
import moment from 'moment-timezone';
import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    CommonPage
  },
  mixins: [common],
  data () {
    return {
      cities: [
        { name: 'Beijing', timezone: 'Asia/Shanghai', time: '', date: '', day: '' },
        { name: 'Chicago', timezone: 'America/Chicago', time: '', date: '', day: '' },
        { name: 'Abu Dhabi', timezone: 'Asia/Dubai', time: '', date: '', day: '' },
        { name: 'Addis Ababa', timezone: 'Africa/Addis_Ababa', time: '', date: '', day: '' },
        { name: 'Amman', timezone: 'Asia/Amman', time: '', date: '', day: '' },
        { name: 'Amsterdam', timezone: 'Europe/Amsterdam', time: '', date: '', day: '' },
        { name: 'Antananarivo', timezone: 'Indian/Antananarivo', time: '', date: '', day: '' },
        { name: 'Athens', timezone: 'Europe/Athens', time: '', date: '', day: '' },
        { name: 'Auckland', timezone: 'Pacific/Auckland', time: '', date: '', day: '' },
        { name: 'Baghdad', timezone: 'Asia/Baghdad', time: '', date: '', day: '' },
        { name: 'Bangkok', timezone: 'Asia/Bangkok', time: '', date: '', day: '' },
        { name: 'Barcelona', timezone: 'Europe/Madrid', time: '', date: '', day: '' }, // 西班牙的主要时区
        { name: 'Beirut', timezone: 'Asia/Beirut', time: '', date: '', day: '' },
        { name: 'Berlin', timezone: 'Europe/Berlin', time: '', date: '', day: '' },
        { name: 'Bogotá', timezone: 'America/Bogota', time: '', date: '', day: '' },
        { name: 'Boston', timezone: 'America/New_York', time: '', date: '', day: '' },
        { name: 'Brussels', timezone: 'Europe/Brussels', time: '', date: '', day: '' },
        { name: 'Buenos Aires', timezone: 'America/Argentina/Buenos_Aires', time: '', date: '', day: '' },
        { name: 'Cairo', timezone: 'Africa/Cairo', time: '', date: '', day: '' },
        { name: 'Cape Town', timezone: 'Africa/Johannesburg', time: '', date: '', day: '' }, // 南非的主要时区
        { name: 'Caracas', timezone: 'America/Caracas', time: '', date: '', day: '' },
        { name: 'Damascus', timezone: 'Asia/Damascus', time: '', date: '', day: '' },
        { name: 'Delhi', timezone: 'Asia/Kolkata', time: '', date: '', day: '' }, // 印度的主要时区
        { name: 'Istanbul', timezone: 'Europe/Istanbul', time: '', date: '', day: '' },
        { name: 'London', timezone: 'Europe/London', time: '', date: '', day: '' },
        { name: 'Dhaka', timezone: 'Asia/Dhaka', time: '', date: '', day: '' },
        { name: 'Dubai', timezone: 'Asia/Dubai', time: '', date: '', day: '' },
        { name: 'Dublin', timezone: 'Europe/Dublin', time: '', date: '', day: '' },
        { name: 'Frankfurt', timezone: 'Europe/Berlin', time: '', date: '', day: '' }, // 德国的主要时区
        { name: 'Guangzhou', timezone: 'Asia/Shanghai', time: '', date: '', day: '' },
        { name: 'Hanoi', timezone: 'Asia/Bangkok', time: '', date: '', day: '' }, // 越南的主要时区
        { name: 'Havana', timezone: 'America/Havana', time: '', date: '', day: '' },
        { name: 'Helsinki', timezone: 'Europe/Helsinki', time: '', date: '', day: '' },
        { name: 'Hong Kong', timezone: 'Asia/Hong_Kong', time: '', date: '', day: '' },
        { name: 'Lagos', timezone: 'Africa/Lagos', time: '', date: '', day: '' },
        { name: 'Honolulu', timezone: 'Pacific/Honolulu', time: '', date: '', day: '' },
        { name: 'Jakarta', timezone: 'Asia/Jakarta', time: '', date: '', day: '' },
        { name: 'Karachi', timezone: 'Asia/Karachi', time: '', date: '', day: '' },
        { name: 'Kathmandu', timezone: 'Asia/Kathmandu', time: '', date: '', day: '' },
        { name: 'Kinshasa', timezone: 'Africa/Kinshasa', time: '', date: '', day: '' },
        { name: 'Kuala Lumpur', timezone: 'Asia/Kuala_Lumpur', time: '', date: '', day: '' },
        { name: 'Kyiv', timezone: 'Europe/Kyiv', time: '', date: '', day: '' },
        { name: 'Las Vegas', timezone: 'America/Los_Angeles', time: '', date: '', day: '' },
        { name: 'Lima', timezone: 'America/Lima', time: '', date: '', day: '' },
        { name: 'Los Angeles', timezone: 'America/Los_Angeles', time: '', date: '', day: '' },
        { name: 'Moscow', timezone: 'Europe/Moscow', time: '', date: '', day: '' },
        { name: 'Luanda', timezone: 'Africa/Luanda', time: '', date: '', day: '' },
        { name: 'Madrid', timezone: 'Europe/Madrid', time: '', date: '', day: '' },
        { name: 'Manila', timezone: 'Asia/Manila', time: '', date: '', day: '' },
        { name: 'Mecca', timezone: 'Asia/Riyadh', time: '', date: '', day: '' }, // 沙特阿拉伯的主要时区
        { name: 'Mexico City', timezone: 'America/Mexico_City', time: '', date: '', day: '' },
        { name: 'Mumbai', timezone: 'Asia/Kolkata', time: '', date: '', day: '' },
        { name: 'New York', timezone: 'America/New_York', time: '', date: '', day: '' },
        { name: 'Paris', timezone: 'Europe/Paris', time: '', date: '', day: '' },
        { name: 'Miami', timezone: 'America/New_York', time: '', date: '', day: '' },
        { name: 'Milan', timezone: 'Europe/Rome', time: '', date: '', day: '' }, // 意大利的主要时区
        { name: 'New Delhi', timezone: 'Asia/Kolkata', time: '', date: '', day: '' },
        { name: 'Nuuk', timezone: 'America/Godthab', time: '', date: '', day: '' } // 格陵兰的主要时区
      ],
      bigTime: null, // 大时间
      localTime: null, // 存储本地时间
      localTimeZone: '', // 存储本地时区
      currentLocation: 'Your City', // 存储当前地区
      isFullScreen: false, // 增加全屏状态变量

      card: [
        {
          img: require('@/assets/card-1.png'),
          title: '9 Time Management Tips to Benefit Your Life',
          desc: 'There is a term called the "busyness trap."It means that in modern society, everyone is constantly on the go, busy but not knowing how to break free from it.',
          routename: 'blog1'
        },
        {
          img: require('@/assets/card-2.png'),
          title: 'The Ultimate Guide to Overcoming Jet Lag',
          desc: 'The pain of overcoming jet lag is something you, who are looking at your phone right now, have surely expe rienced. Today, Alice wants to chat with everyone...',
          routename: 'blog2'
        },
        {
          img: require('@/assets/card-3.png'),
          title: 'How to Properly Plan Your Day?',
          desc: 'In our fast-paced lives, time is like gold, with every second being incredibly precious. We all have 24 hours, but why do some people manage to accomplish...',
          routename: 'blog3'
        },
        {
          img: require('@/assets/card-4.png'),
          title: 'How to Create a Daily Self-Discipline Plan on a Single Sheet of Paper: 7 Dai...',
          desc: 'The 24 hours in a day will pass regardless, but the difference between people lies in their attitude towards time...',
          routename: 'blog4'
        },
      ]
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    const routeCity = this.$route.params.city;
    if (!routeCity) return;
    this.$emit('updateMeta', routeCity); // 触发父组件更新 Meta 的事件
    await this.updateCityFromRoute(routeCity)

  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; // 获取本地时区
    this.bigTime = this.localTimeZone
    this.getLocation(); // 获取用户的地理位置
    const routeCity = this.$route.params.city;
    console.log(routeCity);
    if (routeCity) {
      this.$emit('updateMeta', routeCity); // 触发父组件更新 Meta 的事件
      await this.updateCityFromRoute(routeCity)
    }
    this.updateTimes();
    setInterval(this.updateTimes, 1000); // 每秒更新一次时间
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    async updateCityFromRoute (routeCity) {
      // 获取当前路由中的城市名称
      const matchedCity = this.cities.find(city => city.name.toLowerCase() === routeCity.toLowerCase());

      if (matchedCity) {
        this.currentLocation = matchedCity.name;
        this.bigTime = matchedCity.timezone; // 更新大时间的时区
        this.localTime = moment().tz(matchedCity.timezone); // 直接设置为 moment 对象
      } else {
        console.warn(`未找到匹配的城市: ${routeCity}`);
      }
    },
    updatePageMetadata (cityName) {
      // 修改页面标题
      document.title = `Current Time in ${cityName}`;

      // 获取或创建 description 元标签
      let descriptionMeta = document.querySelector('meta[name="description"]');
      if (!descriptionMeta) {
        descriptionMeta = document.createElement('meta');
        descriptionMeta.name = 'description';
        document.head.appendChild(descriptionMeta);
      }

      // 设置 description 内容
      descriptionMeta.content = `View the current time in ${cityName} and explore other cities.`;
    },
    goto (name) {
      this.$router.push({ name: name });
    },
    showBig () {
      this.isFullScreen = true; // 切换到全屏显示
    },
    exitFullScreen () {
      this.isFullScreen = false; // 退出全屏显示
    },
    updateTimes () {
      // 更新大时间
      const nowLocal = moment().tz(this.bigTime);
      this.localTime = nowLocal; // 直接设置为 moment 对象

      this.cities.forEach(city => {
        const now = moment().tz(city.timezone);
        city.time = now.format('HH : mm'); // 显示时间
        city.date = now.format('MM/DD/YYYY'); // 显示日期
        city.day = now.format('dddd'); // 显示星期
      });
    },
    getLocation () {
      // 获取用户的当前时区
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const cityName = timeZone.split('/')[1] || 'Your City';

      console.log(`当前时区: ${timeZone}`);
      console.log(`推算的城市: ${cityName}`);
      this.currentLocation = cityName
    },
    selectCity (city) {
      this.$router.push({ path: `/${city.name}` });
    }
  }
}
</script>
